import React, { useState } from "react"
import Layout from "../Layout/layout2"
import { Input, Grid, Header, Button, Tab } from "semantic-ui-react";
import { useStaticQuery, graphql } from "gatsby";
import './Homepage.css'
import mobile from '../../../images/mobile.webp'
import desktop from '../../../images/desktop.webp'
import HomepageIpad from '../../../images/homepage-ipad.jpeg'
import computer from '../../../images/computer.png'
import news from "../../../images/news.jpg"
import press from "../../../images/press.jpeg"
import document from '../../../images/Document-package.png'
import seo from '../../../images/seo.webp'
import security from '../../../images/security.png'
import tracking from '../../../images/tracking.png'
import truck from '../../../images/truck.png'
import MickeyOS from '../../../images/mickey-os.png'
import ContractorsDirect from "../../../images/contractor-direct-thumbnail.png"
import MaterialsXchange from '../../../images/mickey-materialsxchange.png'
import MickeyEnergy from '../../../images/Mickey-Energy.png'
import RequestImg from '../../../images/requestimg.webp';
import styled from "styled-components";
import MobileDevices from "../../../images/mx-iphone-tablet.jpg"
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { media } from "../../../../mq"
import OnlineMarketplace from "../../../images/online-marketplace-laptop.jpg"
import OrderManagement from "../../../images/order-management-laptop.jpg"
import Orders from "../../../images/order-laptop.jpg"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { CaretLeft, CaretRight } from "phosphor-react";
import MickeyTabs from "../UtilityComponents/MickeyTabs";
import HomepageAnimation from "../../../images/homepage-animation.gif"
import { Link } from "gatsby";

const HomepageWrapper = styled.div`
  padding: 3rem 0 3rem 0;
  margin: 0 !important;
  ${media.smaller`padding: 5rem 3rem;`}
  .section-container{
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 50px ;
    ${media.smaller`margin-bottom: 100px; `}
    ${media.large`flex-direction: row;`}
    ${media.xl`justify-content: center;`}
    &#hero{
      .hero-left{
        .hero-header{
          font-size: 48px;
          text-align: center;
          ${media.small`font-size: 72px; text-align: left;`}
          .hero-subheader{
            font-size: 16px;
            line-height: 1.8em;
            margin-bottom: 2rem;
          }
        }
      }
      .hero-right{
        text-align: center;
        margin-top: 4rem;
        ${media.large`text-align: right; margin-top: 0;`}
        img{
          width: 490px;
          height: auto;
        }
      }
    }
    &#mickey-as-saas{
      justify-content: center;
      .mickey-as-saas-header{
        .mickey-as-saas-subheader{
          font-family: "Brown Regular";
          font-size: 20px;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
          margin-right: 0;
          margin-left: 0;
          line-height: 1.7em;
          ${media.smallSideways`margin-right: 19%; margin-left: 19%;`}
        }
      }
      .homepage-tabs{
        .tab-pane{
          padding-top: 5rem;
          padding-left: 0;
          padding-right: 0;
          ${media.smaller`padding-left: 1em; padding-right: 1em;`}
          .tab-inner{
            justify-content: center;
            flex-direction:  column;
            ${media.large`flex-direction: row;`}
            .tab-left{
                h3{
                  font-size: 36px;
                  text-align: center;
                  ${media.large`text-align: left;`}
                  p{
                    line-height: 1.8em;
                    font-size: 16px;
                    margin-top: 2rem;
                    font-family: "Brown Regular"
                    ${media.smallMedium`font-size: 20px;`}
    
                  }
                }
            }
            .tab-right{
              display: flex;
              justify-content: center;
              ${media.smaller`display: block; justify-content: flex-start;`}
              img{
                max-height: auto;
                min-width: 300px;
                width: 100%;
                margin-top: 4rem;
                ${media.large`max-height: 470px; width: auto; margin-top: 0;`}
              }

            }
          }
        }

      }

    }
    &#our-features{
      justify-content: center;
      .our-features-header{
        .our-features-subheader{
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
          font-size: 20px;
        }
      }
      .feature-row{
        justify-content: space-between;
        flex-direction: column;
        max-width: 100%;
        ${media.smallMedium`flex-direction: row;`}
        ${media.xl`max-width: 87.5%;`}
        .feature{
          padding: 2rem;
          margin-bottom: 2rem !important;
          img{
            width: auto;
            height: 70px;
            margin: 0;
          }
          h3{
            font-size: 24px;
          }
          p{
            line-height: 1.6em;
            font-size: 16px;
          }
        }
      }

    }
    &#mickey-is-powering{
      justify-content: center;
      .powered-products{
        justify-content: space-between;
        flex-direction: column;
        max-width: 100%;
        ${media.smallMedium`flex-direction: row;`}
        ${media.xl`max-width: 87.5%;`}
        .product{
          padding: 0 !important;
          img{
            width: 100%;
            height: auto;
            margin: 0;
          }
          .product-type{
            margin: 15px 0 15px 0 !important;
            color: #f1592a;
            font-size: 14px;
          }
          h3{
            margin: 0 !important;
            font-size: 24px;
          }
          .product-description{
            line-height: 1.6em;
            font-size: 16px;
            margin: 15px 0 15px 0 !important;
          }
        }
      }
    }
    &#whats-happening{
      justify-content: center;
      .whats-happening-inner-wrapper{
        justify-content: space-evenly;
        flex-direction: column;
        max-width: 100%;
        ${media.smallMedium`flex-direction: row;`}
        .source{
          padding: 2rem;
          img{
            width: 100%;
            height: auto;
            margin: 0;
          }
          p{
            font-size: 14px;
            margin: 15px 0 15px 0 !important;
            color: #f1592a; 
          }
          .source-header{
            margin: 0 !important;
            font-size: 24px;
            .source-subheader{
              font-size: 16px;
              margin-top: 1rem;
              color: #000000 ;
            }
          }

        }
      }
    }
    &#powered-by-investors{
      justify-content: center;
      .powered-header{
        margin-bottom: 2rem;
      }
    }
    &#request-demo{
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      ${media.large`flex-direction: row;`}
      ${media.xl`justify-content: center;`}
      .request-demo-header{
        width: 100%;
        ${media.large`width: 80%;`}
        .request-demo-subheader{
          font-size: 16px;
          line-height: 1.8em;
          margin-bottom: 2rem;
          margin-top: 2rem;
        }
      }
      .request-image-wrapper{
        text-align: center;
        margin-top: 4rem;
        ${media.large`text-align: right; margin-top: 0;`}
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .request-wrapper{
    display: flex;
    height: 125px;
    flex-direction: column;
    align-items: center;
    ${media.small`height: 40px; flex-direction: row;`}
    .ui.input{
      border-radius: 50px;
      border: 1px solid #6d6e70;
      width: 300px;
      margin-right: 0;
      margin-bottom: 1rem;
      ${media.small`margin-right: 2rem; margin-bottom: 0;`}
      input{
        border-radius: 50px;
        border: none;
        width: inherit;
      }
    }
  }

  p{
    font-family: "Brown Regular";
  }
  h2{
    font-size: 36px !important;
    ${media.smaller`font-size: 48px !important;`}
  }
  .plywood_border {
    width: 100%;
    height: 4px;
    background: #f1592a;
    top: 47%;
    ${media.small`width: 433px;`}
  }
  .orange-button-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;
  }
  .ui.button.orange-button{
      height: 40px;
      font-size: .75rem;
      background-color: white;
      border: 1px solid #f1592a;
      color: #f1592a;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s ease-in-out;
      &:hover{
        background-color: #f1592a;
        color: white;
      }
    }
  .ui.attached.tabular.menu{
    border: none !important;
    padding-bottom: 1rem;
    flex-direction: column;
    ${media.smaller`flex-direction: row;`}

    a.item{
      border-bottom: 3px solid #f2f2f2 !important;
      font-family: "Brown Bold";

    }
    a.active.item{
      border-bottom: 3px solid #f1592a !important;
      font-family: "Brown Bold";
      margin-bottom: 0;
    }
    a:first-child{
      display: flex;
      justify-content: center;
      flex: 0 1 33%;
      border: none;
      transition: border-bottom .4s ease-in-out;
      font-family: "BrownLLSub-Regular";
    }
    a:nth-of-type(2){
      display: flex;
      justify-content: center;
      flex: 0 1 33%;
      border: none ;
      transition: border-bottom .2s ease-in-out;
      font-family: "BrownLLSub-Regular";
    }
    a:last-child{
      display: flex;
      justify-content: center;
      flex: 0 1 33%;
      border: none;
      transition: border-bottom .2s ease-in-out;
      font-family: "BrownLLSub-Regular";
    }
  }
  .ui.bottom.attached.segment.active.tab{
    border: none !important;
  }
  .indicators > li{
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
  .react-slideshow-wrapper .images-wrap{
    align-items: center;
  }
  a{
    color: black;
    transition: .2s ease-in-out;
    &:hover{
      color: #f1592a ;
    }
  }

`

const Homepage = () => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      allWpInvestorLogo {
        edges {
          node {
            investorLogos {
              investorLogo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100, width: 300)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const wd = useWindowDimensions()

  const panes = [
    {
      menuItem: "Custom Online Marketplaces",
      content: function () {
        return (
          <Tab.Pane className="tab-pane">
            <Grid className="tab-inner" stackable width={16}>
              <Grid.Column className="tab-left" width={wd?.width <= 1200 ? 16 : 6}>
                <Header as={"h3"}>
                  Automated Custom Marketplaces for your business
                  <Header.Subheader as={"p"}>
                    Mickey allows for you to easily transform your physical inventory into a digital marketplace. Conventional wholesale and commodity-based industries can begin setting up their marketplace by adding products, creating listings, and using many of our built-in tools for payments, fulfillment, and managing orders.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column className="tab-right" width={wd?.width <= 1200 ? 16 : 10}>
                <img
                  src={OnlineMarketplace}
                />
              </Grid.Column>
            </Grid>
          </Tab.Pane >
        )
      }
    },
    {
      menuItem: "Automated Fulfillment",
      content: function () {
        return (

          < Tab.Pane className="tab-pane">
            <Grid className="tab-inner" stackable width={16} >
              <Grid.Column className="tab-left" width={wd?.width <= 1200 ? 16 : 6}>
                <Header as={"h3"}>
                  Streamline your business’s fulfillment
                  <Header.Subheader as={"p"}>
                    Our built-in fulfillment feature automatically gets the best shipping rate from several of our logistics partners including: J.B. Hunt, Echo, Uber Freight, Coyote, and Loadsmart. Our automated fulfillment system can also handle a high volume of orders and shipments, making it easier for your business to scale your operations.
                  </Header.Subheader>
                </Header>

              </Grid.Column>
              <Grid.Column className="tab-right" width={wd?.width <= 1200 ? 16 : 10} >
                <img src={Orders} />
              </Grid.Column>
            </Grid>
          </Tab.Pane >

        )
      }
    },
    {
      menuItem: "Order Management",
      content: function (props) {
        return (
          < Tab.Pane className="tab-pane" >
            <Grid className="tab-inner" stackable width={16} >
              <Grid.Column className="tab-left" width={wd?.width <= 1200 ? 16 : 6}>
                <Header as={"h3"} >
                  Improve your customer experience
                  <Header.Subheader as={"p"}>
                    Mickey’s order management system gives businesses greater control over their operations, enabling them to track and manage orders more effectively and make informed decisions about their business.
                  </Header.Subheader>
                </Header>
              </Grid.Column>
              <Grid.Column className="tab-right" width={wd?.width <= 1145 ? 16 : 10}>
                <img src={OrderManagement} />
              </Grid.Column>
            </Grid>
          </Tab.Pane >
        )
      }
    }

  ]





  const properties = {
    prevArrow:
      <CaretLeft size={32} color="#2b2727" />

    ,
    nextArrow:
      <CaretRight size={32} color="#2b2727" />
  }

  const responsiveSettings = [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },

  ];

  const investorLogos = data?.allWpInvestorLogo?.edges


  const [email, setEmail] = useState("")

  const handleEmail = (inputEmail) => {
    setEmail(inputEmail)
  }
  return (
    <Layout>
      <HomepageWrapper>
        {
          wd && wd?.width && <>
            <Grid
              className="section-container"
              id="hero"
              stackable
              width={16}
            >
              <Grid.Column className="hero-left" width={wd.width >= 1600 ? 6 : 8 && wd.width <= 1200 ? 16 : 8}>
                <Header
                  className="hero-header"
                  as={"h1"}
                >
                  Building B2B <br />
                  Technology <br />
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "1.5rem",
                      alignItems: "center",
                      flexDirection: wd.width <= 710 ? "column" : "row"
                    }}
                  >
                    for
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: wd.width <= 710 ? "" : "1rem"
                      }}
                    >
                      <h4
                        style={{
                          margin: "0"
                        }}
                      >
                        <img src={HomepageAnimation} style={{ margin: "0", height: "100px", paddingTop: "15px" }} />
                      </h4>
                      <div className="plywood_border" />
                    </div>
                  </div>
                  <Header.Subheader
                    className="hero-subheader"
                    as={"p"}
                  >
                    Mickey is a technology company that builds ecommerce marketplaces and platforms for wholesale and commodity products. With proven success in forest products and lumber and building materials, Mickey can customize an online market that fits your industry.
                  </Header.Subheader>
                </Header>
                <div className="request-wrapper">
                  {/* <Input
                    placeholder={"Enter Email..."}
                    onChange={(e, d) => handleEmail(d?.value)}
                  /> */}
                  <Button
                    className="orange-button"
                    href={`/request-demo/?email=${email}&origin=Home%20Page`}
                  >
                    Request Demo
                  </Button>
                </div>
              </Grid.Column>
              <Grid.Column
                className="hero-right"
                width={wd.width >= 1600 ? 6 : 7 && wd.width <= 1200 ? 16 : 7}
              >
                <img src={MobileDevices} />
              </Grid.Column>
            </Grid>
            <Grid
              className="section-container"
              id="mickey-as-saas"
              stackable
              width={wd.width >= 1600 ? 14 : 16}
            >
              <Grid.Column width={wd.width >= 1600 ? 14 : 16} textAlign="center">
                <Header className="mickey-as-saas-header" as={"h2"}>
                  Mickey as SaaS
                  <Header.Subheader className="mickey-as-saas-subheader" as={"h3"}>
                    We help modernize the way you sell and manage your wholesale and commodity products online while making it easier and more transparent for your customers to place orders.
                  </Header.Subheader>
                </Header>
                <div className="orange-button-wrapper">
                  <Button href="/products" className="orange-button">Learn More</Button>
                </div>
                <div className="section-container">
                  <MickeyTabs className="homepage-tabs" data={panes} />
                </div>
              </Grid.Column>
            </Grid>
            <Grid
              className="section-container"
              id="our-features"
              stackable
              width={wd.width >= 1600 ? 14 : 16}
            >
              <Grid.Column width={wd.width >= 1600 ? 14 : 16} textAlign="center">
                <Header className="our-features-header" as={"h2"}>
                  Our Features
                  <Header.Subheader className="our-features-subheader" as={"p"}>
                    We customize platforms to suit your industry's needs and requirements.
                  </Header.Subheader>
                </Header>
                <div className="orange-button-wrapper">
                  <Button href="/products" className="orange-button">Learn More</Button>
                </div>
              </Grid.Column>

              <Grid.Row className="feature-row">
                <Grid.Column className="feature" width={wd.width <= 991 ? 16 : 5}>
                  <img src={security} alt='' />
                  <Header as={"h3"}>
                    Security
                  </Header>
                  <p>
                    We ensure that the latest security measurements are in place to protect you and your customer's data.
                  </p>
                </Grid.Column>
                <Grid.Column className="feature" width={wd.width <= 991 ? 16 : 5} >
                  <img src={truck} alt='' />
                  <Header as={"h3"} >
                    Automated Fulfillment
                  </Header>
                  <p >
                    We build ecommerce platforms that can provide live, real-time inventories, with a no-hassle, automated fulfillment process and a complete, end-to-end purchasing solution. We work with you to understand your industry's unique needs — and build powerful logistics technology around them.
                  </p>
                </Grid.Column>
                <Grid.Column className="feature" width={wd.width <= 991 ? 16 : 5} >
                  <img src={tracking} alt='' />
                  <Header as={"h3"} >
                    Live Tracking
                  </Header>
                  <p >
                    We’ve partnered with the country’s leading trucking and logistics firms ensuring streamlined pick-up and delivery options. With our live tracking option – your customers can track exactly where their order is and when it’s expected to be delivered.
                  </p>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className="feature-row" >
                <Grid.Column className="feature" width={wd.width <= 991 ? 16 : 5} >
                  <img src={seo} alt='' />
                  <Header as={"h3"}>
                    SEO Integration
                  </Header>
                  <p >
                    Boost your online presence and reach a wider audience with Mickey's Automated SEO feature. Our platform optimizes your online marketplace for search engines, increasing your visibility and attracting more customers.
                  </p>
                </Grid.Column>
                <Grid.Column className="feature" width={wd.width <= 991 ? 16 : 5} >
                  <img src={document} alt='' />
                  <Header as={"h3"}>
                    Balance & Invoicing
                  </Header>
                  <p >
                    Mickey’s technology can streamline the entire order billing and invoicing process. We can create automated ordering, balance, electronic receipts, payment invoicing and so much more.
                  </p>
                </Grid.Column>
                <Grid.Column className="feature" width={wd.width <= 991 ? 16 : 5} >
                  <img src={computer} alt='' />
                  <Header as={"h3"} >
                    Easy to Use Interface
                  </Header>
                  <p>
                    Our platform is designed to make our software accessible and user-friendly to all of our clients.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid
              className="section-container"
              id="mickey-is-powering"
              width={wd.width >= 1600 ? 14 : 16}
            >
              <Grid.Column width={wd.width >= 1600 ? 14 : 16} textAlign="center">
                <Header as={"h2"}>
                  Mickey is Powering
                </Header>
              </Grid.Column>
              <Grid.Row className="powered-products" >
                <Grid.Column className="product" width={wd.width <= 991 ? 16 : 5}>
                  <img src={MickeyOS} alt='' />
                  <p className="product-type">Mickey OS</p>
                  <Header as={"h3"}>
                    <Link to="/about">
                      Mickey | OS
                    </Link>
                  </Header>
                  <p className="product-description">
                    Automated B2B Marketplace for wholesale products
                  </p>
                </Grid.Column>
                <Grid.Column className="product" width={wd.width <= 991 ? 16 : 5}>
                  <img src={ContractorsDirect} alt='' />
                  <p className="product-type">Mickey OS</p>
                  <Header as={"h3"}>
                    <Link to="/contractor-direct">
                      Mickey | Contractor Direct
                    </Link>
                  </Header>
                  <p className="product-description">
                    An integrated platform allowing traders to build material lists, send them out as RFQ’s, and book logistics in one place.
                  </p>
                </Grid.Column>

                <Grid.Column className="product" width={wd.width <= 991 ? 16 : 5}>
                  <img style={{ width: "100%", height: "auto" }} src={MaterialsXchange} alt='' />
                  <p className="product-type">Mickey OS</p>
                  <Header as={"h3"}>
                    <a href="https://marketplace.materialsxchange.com/">
                      MaterialsXchange
                    </a>
                  </Header>
                  <p className="product-description">
                    The Digital marketplace for Lumber and Panels
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="powered-products" >
                <Grid.Column className="product" width={wd.width <= 991 ? 16 : 5}>
                  <img src={MickeyEnergy} alt='' />
                  <p className="product-type" >Private Software</p>
                  <Header as={"h3"} >
                    <Link to="/mickey-energy">
                      Mickey | Energy
                    </Link>
                  </Header>
                  <p className="product-description">
                    Trading Technology for Liquified Natural Gas
                  </p>
                </Grid.Column>
              </Grid.Row>
              {/* <Grid.Row className="powered-products" >
                <Grid.Column className="product" width={wd.width <= 991 ? 16 : 5}>
                  <img src={ContractorsDirect} alt='' />
                  <p className="product-type">Contractor Direct</p>
                  <Header as={"h3"}>
                    <Link to="/contractor-direct">
                      Contractor Direct
                    </Link>
                  </Header>
                  <p className="product-description">
                    An integrated platform allowing traders to build material lists, send them out as RFQ’s, and book logistics in one place.
                  </p>
                </Grid.Column>

              </Grid.Row> */}
            </Grid>
            <Grid
              className="section-container"
              id="whats-happening"
              width={wd.width >= 1600 ? 14 : 16}
            >
              <Grid.Column width={wd.width >= 1600 ? 14 : 16} textAlign="left">
                <Header textAlign="center" as={"h2"}>
                  What's Happening
                </Header>
              </Grid.Column>
              <Grid.Row className="whats-happening-inner-wrapper" >
                <Grid.Column className="source" width={wd.width <= 991 ? 16 : 7} >
                  <img src={news} alt='' />
                  <p style={{}}>Mickey OS</p>
                  <Header as={"h3"} className="source-header">
                    <Link to="/news">
                      News
                    </Link>
                    <Header.Subheader className="source-subheader" as={"p"}>
                      Read all bout Mickey in the news with our features articles here.
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column className="source" width={wd.width <= 991 ? 16 : 7} >
                  <img src={HomepageIpad} alt='' />
                  <p >Mickey OS</p>
                  <Header className="source-header" as={"h3"} >
                    <Link to="/press">
                      Press
                    </Link>
                    <Header.Subheader className="source-subheader" as={"p"}>
                      Up-to-date press releases from Mickey
                    </Header.Subheader>
                  </Header>
                </Grid.Column>

              </Grid.Row>
            </Grid>


            <Grid
              className="section-container"
              id="powered-by-investors"
              width={wd.width >= 1600 ? 14 : 16}
            >
              <Grid.Column width={wd.width >= 1600 ? 14 : 16} textAlign="center">
                <Header className="powered-header" as={"h2"}>
                  Powered By Investors
                </Header>
                <Slide
                  duration={0}
                  transitionDuration={5000}
                  slidesToShow={wd.width <= 540 ? 1 : 4}
                  slidesToScroll={1}
                  responsive={responsiveSettings}
                  indicators={false}
                  autoplay={true}
                  arrows={false}
                  {...properties}
                >
                  {
                    investorLogos?.map((logo, index) => {
                      const image = getImage(logo.node.investorLogos.investorLogo.localFile)
                      return (
                        <div style={{ width: "250px" }}>
                          <GatsbyImage
                            image={image}
                          />
                        </div>
                      )
                    })
                  }
                </Slide>
              </Grid.Column>
            </Grid>

            <Grid
              className="section-container"
              id="request-demo"
              stackable
              width={16}
            >
              <Grid.Column
                width={wd.width >= 1600 ? 6 : 8 && wd.width <= 1200 ? 16 : 8}
              >
                <Header
                  className="request-demo-header"
                  as={"h2"}
                  textAlign={wd.width <= 768 ? "center" : "left"}
                >
                  Request a demo with Mickey today.
                  <Header.Subheader
                    className="request-demo-subheader"
                    as={"p"}
                  >
                    Request a demo with us to better understand how Mickey can be implemented at your company.
                  </Header.Subheader>
                </Header>
                <div className="request-wrapper">
                  <Input
                    placeholder={"Enter Email..."}
                    onChange={(e, d) => handleEmail(d?.value)}
                  />
                  <Button
                    className="orange-button"
                    href={`/request-demo/?email=${email}&origin=Home%20Page`}
                  >
                    Request Demo
                  </Button>
                </div>
              </Grid.Column>
              <Grid.Column
                className="request-image-wrapper"
                width={wd.width >= 1600 ? 8 : 7 && wd.width <= 1200 ? 16 : 7}
              >
                <img
                  src={RequestImg}
                />
              </Grid.Column>
            </Grid>
          </>
        }
      </HomepageWrapper>
    </Layout >
  )
}

export default Homepage
